import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import clsx from 'clsx'
import * as css from './ltc-quoter.module.css'

const LtcQuoterPg = () => {
	const ref = React.createRef()

	React.useEffect(() => {
		const interval = setInterval(() => {
			ref.current?.submit()
		} , 1000)

		return () => clearInterval(interval)
	} , [ref])

	return (
		<DefaultLayout title="Traditional LTC">
			<section className="container padding">
			<h1 className="h1">LTC Quoter</h1>

				<form className={css.form} ref={ref} method="POST" action="https://www.ltcselect.com">
					<input type="hidden" name="Action" value="SetLogin"></input>
					<input type="hidden" name="AccountName" value="cpsuser"></input>
					<input type="hidden" name="Password" value="cpspw"></input>

					<p><button className="action">Click here to connect</button></p>

					<p>Make sure you are <button className={clsx(css.login, 'link')}>logged in</button> or try refreshing the page.</p>
				</form>
			</section>
		</DefaultLayout>
	)
}

export default LtcQuoterPg
